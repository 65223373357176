<template lang="pug">
ul
  XyzTransitionGroup(appear, xyz="fade stagger left-1 up-1")
    li(v-for="(itemText, index) in listText", :key="index") 
      .item
        BaseCustomIcon.icon(nameIcon="mdiCheckCircle", :size="24")
        .text {{ itemText }}
</template>

<script>
export default {
  name: "list",
  props: {
    listText: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  @apply pt-8;
  li {
    .item {
      @apply flex items-center text-green-500;
      .text {
        @apply pl-2 text-sm text-gray-500 font-medium;
      }

      .icon {
        @apply flex-shrink-0;
      }
    }

    & + li {
      @apply mt-4;
    }
  }
}
</style>
