<template lang="pug">
.card
  slot
</template>

<script>
export default {
  name: "card",
};
</script>

<style lang="scss" scoped>
.card {
  @apply bg-white rounded-xl p-12 shadow-md w-full h-full;
}
</style>
