<template lang="pug">
.card-button
  slot
</template>

<script>
export default {
  name: "card-button",
};
</script>

<style lang="scss" scoped>
.card-button {
  @apply text-center py-3 w-full mt-8 bg-gray-700 rounded-lg font-bold text-white text-lg;
}
</style>
