<template lang="pug">
CardComponent.flex-grow.max-w-lg
  paragraphCard
    .text-2xl {{ item.downloads_sum }} Descargas
  p.text-sm.text-center.font-medium.pt-1.text-blue-400 Pago unico
  paragraphCard.price {{ price }}
  buttonCard Comprar
</template>

<script>
import ParseCash from "@/composables/parse-cash";
const { parseCash } = ParseCash();
import CardComponent from "./components/card.vue";
import paragraphCard from "./components/card-p.vue";
import buttonCard from "./components/card-button.vue";

export default {
  name: "card-purchase",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  computed: {
    downloads() {
      return this.item.downloads_sum;
    },
    price() {
      return parseCash(
        this.item.price_purchase,
        this.item.product_currency,
        this.item.product_sign
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
