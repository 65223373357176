<template lang="pug">
.flex.flex-col
  .container
    .max-w-4xl.mx-auto
      .prices-component
        .col-1
          h1 {{ $t('pricesPage.title') }}
          listComponent(:listText="listText")
  .bg-blue-50.pb-16.pt-8
    .container
      .max-w-4xl.mx-auto
        h1.text-center.pb-12 {{ $t('pricesPage.title_2') }}
        .col-2(v-if="productsType == 'subscription'")
          .group-cards
            CardSubscription(:item="defaultProduct")
          paragraphCard.text-gray-500.pt-12.font-medium {{ $t('pricesPage.description') }}
        .col-2(v-if="productsType == 'purchase'")
          .flex.gap-8.flex-grow.justify-center
            CardPurchase(
              v-for="item in listProducts",
              :item="item",
              :key="item._id"
            )
</template>

<script>
import CardComponent from "./components/card.vue";
import paragraphCard from "./components/card-p.vue";
import buttonCard from "./components/card-button.vue";
import listComponent from "./components/list.vue";

import CardPurchase from "./card-purchase.vue";
import CardSubscription from "./card-subscription.vue";

export default {
  name: "prices-component",
  components: {
    CardComponent,
    paragraphCard,
    buttonCard,
    listComponent,
    CardPurchase,
    CardSubscription,
  },
  computed: {
    listText() {
      return this.$tm('pricesPage.prices_list_options')
    },
    defaultProduct() {
      return this.$store.getters["ProductsStore/getproductDefault"];
    },
    productsType() {
      return this.$store.getters["ProductsStore/getproductDefault"]
        .product_type;
    },
    listProducts() {
      const products = this.$store.getters["ProductsStore/getListProducts"];
      return products.sort(
        (productActual, productBefore) =>
          productActual.downloads_sum - productBefore.downloads_sum
      );
    },
  },
  methods: {
    parseCashPurchases() { },
  },
};
</script>

<style lang="scss" scoped>
.prices-component {
  @apply flex gap-4 py-24 flex-col items-center;

  .col-1 {
    @apply flex flex-col justify-center flex-grow;
  }
  .col-2 {
    @apply flex justify-center flex-grow;
  }
}

.group-cards {
  @apply flex gap-8 flex-grow flex-col sm:flex-row;
}

h1 {
  @apply font-bold text-3xl pr-4 text-gray-700;
}
</style>
